import React from 'react';
import ReactPaginate from 'react-paginate';

interface IProps {
    currentPage: number,
    totalPage: number,
    callback: any
}


const Paginate = ({totalPage, callback, currentPage}: IProps) => {
    return(
        <div className="d-flex align-items-center justify-content-end h-60px">
            <PaginateComponent currentPage={currentPage} totalPage={totalPage} callback={callback}/>
        </div>
    )
}

const PaginateComponent = ({totalPage, callback, currentPage}: IProps) => {
    // @ts-ignore
    return (<ReactPaginate
      previousLabel={<i className="previous"/>}
      nextLabel={<i className="next"/>}
      breakLabel={'...'}
      breakClassName={'break-me'}
      pageCount={totalPage}
      forcePage={currentPage}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={callback}
      containerClassName={'pagination'}
      pageClassName={'paginate_button page-item'}
      pageLinkClassName={'page-link'}
      activeClassName={'active'}
      previousClassName={'paginate_button page-item previous'}
      previousLinkClassName={'page-link'}
      nextClassName={'paginate_button page-item next'}
      nextLinkClassName={'page-link'}
    />)
}

export default Paginate;


