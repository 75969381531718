import {getRequest, IApiResponse} from '../../common/helpers/RequestHelper'
import {categoryStore} from './CategoryStore'


class CategoryService {

  public getCate(): Promise<IApiResponse>{
    return getRequest(`/company_info/v1/category?page=${categoryStore.page}&size=10${categoryStore.name ? `&name=${categoryStore.name.trim()}` : ''}${categoryStore.parentId ? `&parentId=${categoryStore.parentId}` : ''}`)
  }

  public getDetailCate(id: any): Promise<IApiResponse>{
    return getRequest(`/company_info/v1/category/${id}`)
  }

}

export const categoryService = new CategoryService()

