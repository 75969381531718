import {makeAutoObservable} from "mobx";
import {getRequest} from "../../common/helpers/RequestHelper";

class CompanyStore {
    public page: number = 0
    public totalPage: number = 0
    public pageLog: number = 0
    public totalPageLog: number = 0
    public province: string = ''
    public name: string = ''
    public isLoading: boolean = false
    public isLoadingLog: boolean = false
    public listCompany: any[] = []
    public listCompanyLog: any[] = []
    public dataDetail: any = {
        id: '',
        name: "",
        logo: "",
        categories: [],
        province: "",
        address: "",
        phone: "",
        email: '',
        website: "",
        fax: '',
        introduce: "",
        products: [],
        contactInfo: '',
        companyType: "",
        taxCode: "",
        foundedYear: "",
        mainMarketing: '',
        numberEmployees: '',
        certificate: '',
        processStatus: true,
    }


    constructor() {
        makeAutoObservable(this)
    }

    async getCompany(){
        this.isLoading = true
        const result = await getRequest(`/company_info/v1/company?page=${this.page}&size=10${this.name ? `&name=${this.name.trim()}` : ''}${this.province ? `&province=${this.province.trim()}` : ''}`)
        this.isLoading = false
        if(result.status === 200){
            this.listCompany = result.body.data
            this.totalPage = result.body.metadata.totalPage
        }
    }

    async detailCompany(id: any){
        const result = await getRequest(`/company_info/v1/company/${id}`)

        if(result.status === 200){
            this.dataDetail = result.body
        }
    }

    async companyLog(id: any){
        this.isLoadingLog = true
        const result = await getRequest(`/company_info/v1/company_log?page=0&size=20&companyId=${id}`)
        this.isLoadingLog = false
        if(result.status === 200){
            this.listCompanyLog = result.body.data
            this.totalPageLog = result.body.metadata.totalPage
        }
    }

    async readMore(id: any){
        this.isLoadingLog = true
        const result = await getRequest(`/company_info/v1/company_log?page=${this.pageLog}&size=20&companyId=${id}`)
        this.isLoadingLog = false
        if(result.status === 200){
            this.listCompanyLog = this.listCompanyLog.concat(result.body.data)
            this.totalPageLog = result.body.metadata.totalPage
        }
    }

}

export const companyStore = new CompanyStore()