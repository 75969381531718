import {observer} from "mobx-react-lite";
import React, {useEffect} from "react";
import {getIDParameter, getLocalDateTime} from "../../common/utils/Utils";
import {companyStore} from "./CompanyStore";
import {Link} from "react-router-dom";


const CompanyDetail = () => {

    useEffect(() => {
        let id = getIDParameter()
        companyStore.detailCompany(id)
        companyStore.companyLog(id)
    }, [])

    const readMore = async () => {
        let id = getIDParameter()
        companyStore.pageLog++
        await companyStore.readMore(id)
    }

    return (
        <div id="kt_app_content" className="app-content flex-column-fluid ">
            <div id="kt_app_content_container" className="app-container">
                <div className="card card-flush">
                    <div className="card-body pt-0">
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                            <li className="breadcrumb-item text-muted">
                                <Link to="/company" className="text-muted text-hover-primary">Công Ty</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <span className="bullet bg-gray-400 w-5px h-2px"/>
                            </li>

                            <li className="breadcrumb-item text-muted">
                                {companyStore.dataDetail.name}
                            </li>
                        </ul>
                        <div className="row mt-4">
                            <div className="col-9" style={{borderRight: `1px solid #eff2f5`, paddingRight: 20}}>
                                <h2>{companyStore.dataDetail.name}</h2>
                                <div className="d-flex mt-5">
                                    <div className="me-7 mb-5">
                                        <div className="symbol border p-5 symbol-100px symbol-md-120px">
                                            <img src={companyStore.dataDetail.logo} alt=""/>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <p>{companyStore.dataDetail.address}</p>
                                        {companyStore.dataDetail.phone &&
                                        <p><span className="text-muted text-hover-primary">Điện thoại: </span>
                                            <strong>{companyStore.dataDetail.phone}</strong>
                                        </p>}
                                        {companyStore.dataDetail.email && <p><a style={{color: '#3B65A7'}} href={`mailto:${companyStore.dataDetail.email}`}>{companyStore.dataDetail.email}</a>
                                        </p>}
                                        <a href={'http://' + companyStore.dataDetail.website}
                                           target="_blank">{companyStore.dataDetail.website}</a>
                                    </div>
                                </div>
                                <div className="introduce">
                                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0 mb-5">
                                        Giới thiệu chung
                                    </h1>
                                    <p>{companyStore.dataDetail.introduce}</p>
                                </div>

                                {companyStore.dataDetail.products && companyStore.dataDetail.products.length > 0 && <>
                                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0 mb-5">
                                        Ngành nghề kinh doanh
                                    </h1>
                                    <div className="cate mb-5">
                                        {companyStore.dataDetail.categories.map((item: any, i: number) => {
                                            return (
                                                <p key={i}>{item.name}</p>
                                            )
                                        })}
                                    </div>
                                </>}
                                {companyStore.dataDetail.products && companyStore.dataDetail.products.length > 0 &&
                                <>
                                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0 mb-5">
                                        Sản phẩm dịch vụ
                                    </h1>
                                    <div className="product d-flex flex-wrap">
                                        {companyStore.dataDetail.products.map((item: any, i: number) => {
                                            return (
                                                <p className="w-50" key={i}>{item.name}</p>
                                            )
                                        })}
                                    </div>
                                </>}
                                <div className="company_info mt-5">
                                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0 mb-5">
                                        Hồ sơ công ty
                                    </h1>
                                    <div className="d-flex mb-3">
                                        <label className="text-muted text-hover-primary" style={{width: 140}}>Tên công ty:</label>
                                        <span><strong>{companyStore.dataDetail.name}</strong></span>
                                    </div>
                                    <div className="d-flex mb-3">
                                        <label className="text-muted text-hover-primary" style={{width: 140}}>Loại hình công ty:</label>
                                        <span>{companyStore.dataDetail.companyType}</span>
                                    </div>
                                    <div className="d-flex mb-3">
                                        <label className="text-muted text-hover-primary" style={{width: 140}}>Mã số thuế:</label>
                                        <span>{companyStore.dataDetail.taxCode}</span>
                                    </div>
                                    <div className="d-flex">
                                        <label className="text-muted text-hover-primary" style={{width: 140}}>Năm thành lập:</label>
                                        <span>{companyStore.dataDetail.foundedYear}</span>
                                    </div>
                                </div>

                            </div>
                            <div className="col-3">
                                <div style={{paddingLeft: 24}}>
                                    <h1 className="page-heading d-flex text-dark fw-bold fs-2 flex-column justify-content-center my-0 mb-8 mt-4">
                                        Lịch sử xem
                                    </h1>
                                    {companyStore.listCompanyLog && companyStore.listCompanyLog.map((item: any, i) => {
                                        return (
                                            <div key={i} style={{borderBottom: `1px solid #eff2f5`}} className={`${i+1 === companyStore.listCompanyLog.length ? 'border-0' : ''} d-flex w-100 align-items-center pb-2 mb-2 justify-content-between`}>
                                                <div>
                                                    <p className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">{item.user?.username}</p>
                                                    <span className="text-muted text-hover-primary">{item.user?.phone}</span>
                                                </div>
                                                <span className="text-muted text-hover-primary">{item.user?.createdAt && getLocalDateTime(item.user.createdAt, 'dd/mm/yyyy, hh:m_m')}</span>
                                            </div>
                                        )
                                    })}
                                    {(companyStore.pageLog + 1 < companyStore.totalPageLog) && companyStore.totalPageLog > 1 &&
                                    <div style={{marginTop: 25}} className="d-flex justify-content-center">
                                        <button className="btn btn-sm fw-bold btn-primary" onClick={() => readMore()}
                                                disabled={companyStore.isLoadingLog}>Xem thêm
                                        </button>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(CompanyDetail)