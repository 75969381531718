import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import CompanyPage from "../pages/company/CompanyPage"
import CategoryPage from "../pages/category/CategoyPage";
import CompanyDetail from "../pages/company/CompanyDetail";

const PrivateRoutes = () => {
  const UserPage = lazy(() => import('../pages/users/UserPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/company' />} />
        <Route path='/category' element={<SuspendedView><CategoryPage /></SuspendedView>} />
        <Route path='/users' element={<SuspendedView><UserPage /></SuspendedView>} />
        <Route path='/company' element={<SuspendedView><CompanyPage /></SuspendedView>} />
        <Route path='/company-detail/:slug/:id' element={<SuspendedView><CompanyDetail /></SuspendedView>} />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspendedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  
  // @ts-ignore
  return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>
}

export {PrivateRoutes}
