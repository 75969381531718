/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from "./AsideMenuItemWithSub";

export function AsideMenuMain() {


    return (
        <>
            <AsideMenuItem
                to='/company'
                title='Công ty'
                fontIcon='bi-layers'
            />
            <AsideMenuItem
                to='/category'
                title='Danh mục'
                fontIcon='bi-layers'
            />
            <AsideMenuItem
                to='/users'
                title='Tài khoản'
                fontIcon='bi-app-indicator'
            />
            {/*<AsideMenuItemWithSub*/}
            {/*  to='/pages'*/}
            {/*  title='Pages'*/}
            {/*  fontIcon='bi-archive'*/}
            {/*  icon='/media/icons/duotune/general/gen022.svg'*/}
            {/*>*/}
            {/*  <AsideMenuItem*/}
            {/*    to='/profile'*/}
            {/*    title='Connections'*/}
            {/*    hasBullet={true}*/}
            {/*  />*/}
            {/*</AsideMenuItemWithSub>*/}

            {/*<AsideMenuItemWithSub to='/profile' title='Profile' hasBullet={true}>*/}
            {/*  <AsideMenuItem*/}
            {/*    to='/profile/connections'*/}
            {/*    title='Connections'*/}
            {/*    hasBullet={true}*/}
            {/*  />*/}
            {/*</AsideMenuItemWithSub>*/}

        </>
    )
}
