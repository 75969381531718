import React, {useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import Paginate from '../../common/components/Paginate'
import NoContent from '../../common/components/NoContent'
import Loading from '../../common/components/Loading'
import {companyStore} from "./CompanyStore";
import {Link} from "react-router-dom";
import {slug, sttPage} from "../../common/utils/Utils";


const CompanyPage = () => {

    const handlePageClick = async (data: any) => {
        companyStore.page = data.selected;
        await companyStore.getCompany();
    };

    useEffect(() => {
        companyStore.getCompany();
    }, [])

    return (
        <>
            <div className='d-flex align-items-center justify-content-between mb-6'>
                <h1 className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>Công Ty</h1>
            </div>
            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                <div className="card-title d-flex">
                    <div className="d-flex align-items-center position-relative my-1 me-6">
                        <i className="bi bi-search cursor-pointer fs-1x fs-3 position-absolute ms-4" onClick={async () => companyStore.getCompany()}/>
                        <input type="text" value={companyStore.name} onChange={(e: any) => companyStore.name = e.currentTarget.value} onKeyDown={async (e: any) => {
                            if(e.key === 'Enter'){
                                await companyStore.getCompany()
                            }
                        }} data-kt-ecommerce-order-filter="search" className="form-control form-control-solid w-250px ps-12" placeholder="Tìm kiếm theo Tên Cty"/>
                    </div>
                    <div className="d-flex align-items-center position-relative my-1">
                        <i className="bi bi-search fs-1x fs-3 cursor-pointer position-absolute ms-4" onClick={async () => companyStore.getCompany()}/>
                        <input type="text" value={companyStore.province} onChange={(e: any) => companyStore.province = e.currentTarget.value} onKeyDown={async (e: any) => {
                            if(e.key === 'Enter'){
                                await companyStore.getCompany()
                            }
                        }} data-kt-ecommerce-order-filter="search" className="form-control form-control-solid w-250px ps-12" placeholder="Tìm kiếm theo Địa chỉ"/>
                    </div>
                </div>
            </div>
            {companyStore.isLoading ? <Loading/> : <div className='table-responsive'>
                {companyStore.listCompany && companyStore.listCompany.length > 0 ?
                    <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                        <thead>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                            <th>STT</th>
                            <th>Logo</th>
                            <th>Tên công ty</th>
                            <th>Địa chỉ</th>
                            <th>Website</th>
                            <th>Thao tác</th>
                        </tr>
                        </thead>
                        <tbody>
                        {companyStore.listCompany.map((item: any, i: number) => {
                            return (
                                <tr key={i}>
                                    <td>{sttPage(companyStore.page, i)}</td>
                                    <td>
                                        {item.logo && <div className="symbol symbol-30px symbol-md-40px">
                                            <img src={item.logo} alt=""/>
                                        </div>}
                                    </td>
                                    <td>{item.name}</td>
                                    <td>{item.province}</td>
                                    <td>{item.website}</td>
                                    <td>
                                        <Link className="btn btn-sm fw-bold btn-primary"
                                              to={`/company-detail/${slug(item.name)}/${item.id}`}>Chi tiết</Link>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table> : <NoContent/>}

                <Paginate callback={handlePageClick} totalPage={companyStore.totalPage} currentPage={companyStore.page}/>
            </div>}
        </>
    )
}

export default observer(CompanyPage)