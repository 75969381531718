import {getRequest, IApiResponse} from '../../common/helpers/RequestHelper'

class ProfileService {

  public getProfile(): Promise<IApiResponse>{
    return getRequest(`/company_info/v1/user/me`)
  }

}

export const profileService = new ProfileService();