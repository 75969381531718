import React, {useEffect} from 'react'
import {categoryStore} from './CategoryStore'
import {observer} from 'mobx-react-lite'
import Paginate from '../../common/components/Paginate'
import NoContent from '../../common/components/NoContent'
import Loading from '../../common/components/Loading'
import {getLocalDateTime, sttPage} from "../../common/utils/Utils";



const CategoryPage = () => {

  const handlePageClick = async (data: any) => {
    categoryStore.page = data.selected;
    await categoryStore.getCategories();
  };

  useEffect(() => {
    categoryStore.getCategories()
  }, [])

  return (
    <>
      <div className='d-flex align-items-center justify-content-between mb-6'>
        <h1 className='d-flex text-dark fw-bolder fs-3 align-items-center my-1'>Danh Mục</h1>
      </div>
      <div className="card-header align-items-center py-5 gap-2 gap-md-5" data-select2-id="select2-data-124-7sdr">
        <div className="card-title d-flex">
          <div className="d-flex align-items-center position-relative my-1">
            <i className="bi bi-search cursor-pointer fs-1x fs-3 position-absolute ms-4" onClick={async () => categoryStore.getCategories()}/>
            <input type="text" value={categoryStore.name} onChange={(e: any) => categoryStore.name = e.currentTarget.value} onKeyDown={async (e: any) => {
              if(e.key === 'Enter'){
                await categoryStore.getCategories()
              }
            }} className="form-control form-control-solid w-250px ps-12" placeholder="Tìm kiếm danh mục"/>
          </div>
        </div>
      </div>
      {categoryStore.isLoading ? <Loading/> : <div className='table-responsive'>
        {categoryStore.listCate && categoryStore.listCate.length > 0 ?  <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
          <thead>
            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
              <th>STT</th>
              <th>Tên danh mục</th>
              <th>Ngày tạo</th>
              <th>Ngày cập nhật</th>
            </tr>
          </thead>
          <tbody>
          {categoryStore.listCate.map((item: any, i: number) => {
            return (
              <tr key={i}>
                <td>{sttPage(categoryStore.page, i)}</td>
                <td>{item.name}</td>
                <td>{getLocalDateTime(item.createdAt, 'dd/mm/yyyy, hh:m_m')}</td>
                <td>{getLocalDateTime(item.updatedAt, 'dd/mm/yyyy, hh:m_m')}</td>
              </tr>
            )
          })}
          </tbody>
        </table> : <NoContent/>}
        <Paginate callback={handlePageClick} totalPage={categoryStore.totalPage} currentPage={categoryStore.page}/>
      </div>}
    </>
  )

}

export default observer(CategoryPage)