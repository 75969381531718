import {makeAutoObservable} from 'mobx'
import {categoryService} from './CategoryService'
import HttpStatusCode from '../../common/constants/HttpErrorCode'


class CategoryStore {
  id: any = ''
  page: any = 0
  name: any = ''
  parentId: any = ''
  totalPage: any = 0
  listCate: any[] = []
  isLoading: boolean = false

  constructor() {
    makeAutoObservable(this)
  }


  async getCategories() {
    this.isLoading = true;
    const result = await categoryService.getCate()
    this.isLoading = false;
    if (result.status === HttpStatusCode.OK) {
      this.listCate = result.body.data;
      this.totalPage = result.body.metadata.totalPage
    }
  }

}


export const categoryStore = new CategoryStore();